import React from 'react'

export default function About() {
  return (
    <div className="aboutMeDescription">
      <h3 className="aboutMeH3">About Me</h3>
          <img className="avatar" src="https://res.cloudinary.com/darnycya/image/upload/v1686699692/Darnycya_Avi_weza4c.png" alt="avi"></img><p className="aboutMeP"> At the age of 13, I stumbled upon the world of coding. Little did I know, this discovery would shape my future. Friends began paying me $30 each to customize their MySpace layouts, and I found pure joy in it. However, my focus was initially set on business management, and I pursued a career in retail. Eventually, I climbed the ladder to become a Regional General Manager at a prominent maternity company, all while continuing my web development endeavors on the side.<br />
          <br />
          During my ascent in the retail industry, my love for web development continued to grow. It ignited a burning desire within me, but I faced uncertainty about transitioning into a full-fledged Software Engineer role. Questions plagued my mind: Did I need a degree? Would it be challenging? Was the pay worthwhile? With one child and another on the way, I took a leap of faith and enrolled in General Assembly's Software Engineering cohort. That decision marked a turning point in my life.<br />
            <br />
            Fast forward four years, and I can confidently say that taking that leap was the best decision I ever made. I am now living my dream as a Software Engineer, contributing to impactful projects and embracing continuous growth. Each day, I expand my knowledge and creativity in this exciting field.<br />
            <br />
            I invite you to join me on this exciting journey as we explore the limitless possibilities of web development. Thank you for visiting, and I look forward to connecting with you soon!
      </p>
      <br />
      <div className="skillContainer">
      <span>Skills:</span>
        <ul className="aboutUl">
          <li className="aboutLi"><p className="skillsp">JavaScript</p></li>
          <li className="aboutLi"><p className="skillsp">React</p></li>
          <li className="aboutLi"><p className="skillsp">HTML/CSS</p></li>
          <li className="aboutLi"><p className="skillsp">Redux</p></li>
          <li className="aboutLi"><p className="skillsp">Next.js</p></li>
          <li className="aboutLi"><p className="skillsp">Node.js</p></li>
          <li className="aboutLi"><p className="skillsp">Express.js</p></li>
          <li className="aboutLi"><p className="skillsp">PostgreSQL</p></li>
          <li className="aboutLi"><p className="skillsp">TypeScript</p></li>
          <li className="aboutLi"><p className="skillsp">MongoDB</p></li>
          <li className="aboutLi"><p className="skillsp">Git</p></li>
          <li className="aboutLi"><p className="skillsp">Docker</p></li>
          <li className="aboutLi"><p className="skillsp">Bootstrap</p></li>
          <li className="aboutLi"><p className="skillsp">TailwindCss</p></li>
          <li className="aboutLi"><p className="skillsp">WordPress</p></li>
          <li className="aboutLi"><p className="skillsp">Netlify</p></li>
          <li className="aboutLi"><p className="skillsp">Gatsby</p></li>
          <li className="aboutLi"><p className="skillsp">Heroku</p></li>
          <li className="aboutLi"><p className="skillsp">Adobe Experience Platform</p></li>
        </ul>
        </div>
    
      </div>
  )
}
