import React from 'react'
import Link from 'react-scroll/modules/components/Link'

export default function NavBar() {
  return (
    <div className="nav">
      <ul className="navUl">
        <li className="navLi"><Link activeClass="active" smooth spy to="home">- Home -</Link></li> 
        <li className="navLi"><Link activeClass="active" smooth spy to="about">- About Me -</Link></li> 
        <li className="navLi"><Link activeClass="active" smooth spy to="projects">- Projects -</Link></li> 
        <li className="navLi"><Link activeClass="active" smooth spy to="contact">- Contact Me -</Link></li> 
    </ul>
    </div>
  )
}
