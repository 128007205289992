import React from 'react'

export default function Projects() {
  return (
    <div className="projectContainer">
      <h2 className="projectH3">My Projects</h2>
      <div className="projectCardsContainer">
        <div className="projectCards">
        <div className="projectImage"><a target="_blank" rel="noopener noreferrer" href="https://avatar-memory-game.netlify.app/">
          <img className="projectImage" alt="avatar-game" src="https://res.cloudinary.com/darnycya/image/upload/v1686694519/Screen_Shot_2021-11-03_at_9.24.17_PM_yikngy.png" /></a>
        </div>
          
        <div className="projectDescription">
            <h5>Avatar: The Last Airbender Memory Game</h5>
            <p className="projectP">A memory card game using characters from Nickelodeon's Avatar: The Last Airbender.</p>
            <p className="projectP"><b>Tools used:</b>  </p>
            <br />
            <img alt="ReactJS" className="skills-image" src="https://www.pngitem.com/pimgs/m/664-6644509_icon-react -js-logo-hd-png-download.png"></img>
            <img alt="Javascript" className="skills-image" src="https://res.cloudinary.com/highereducation/image/upload/f_auto,fl_lossy,q_auto:eco/v1/ComputerScience.org/2015/10/js_logo-150x150.jpg"></img>
            <img alt="HTML" className="skills-image" src="https://res.cloudinary.com/highereducation/image/upload/f_auto,fl_lossy,q_auto:eco/v1/ComputerScience.org/2015/10/HTML5_logo-e1458852233788.png"></img>
            <img alt="CSS" className="skills-image" src="https://res.cloudinary.com/darnycya/image/upload/v1616420439/CSS3_logo_and_wordmark.svg_tmnjsl.png"></img>
        </div>
      </div>
        

        <div className="projectCards">
        <div className="projectImage">
          <a target="_blank" rel="noopener noreferrer" href="https://escape-nyc-app.netlify.app/"><img className="projectImage" alt="escapeNYC" src="https://res.cloudinary.com/darnycya/image/upload/v1632677865/Screen_Shot_2021-09-26_at_1.14.17_PM_vpzkbf.png" /></a>
        </div>

            <div className="projectDescription">
            <h5>EscapeNYC</h5> 
             
            <p className="projectP">An app that allows users to share, create, delete and edit hiking trails while searching for hiking trails they've found close to NYC.</p>
             
            <p className="projectP"><b>Tools used:</b>  </p>
            <br />
            <img alt="ReactJS" className="skills-image" src="https://www.pngitem.com/pimgs/m/664-6644509_icon-react -js-logo-hd-png-download.png"></img>
            <img alt="HTML" className="skills-image" src="https://res.cloudinary.com/highereducation/image/upload/f_auto,fl_lossy,q_auto:eco/v1/ComputerScience.org/2015/10/HTML5_logo-e1458852233788.png"></img>
            <img alt="CSS" className="skills-image" src="https://res.cloudinary.com/darnycya/image/upload/v1616420439/CSS3_logo_and_wordmark.svg_tmnjsl.png"></img>
            <img alt="Heroku" className="skills-image" src="https://dailysmarty-production.s3.amazonaws.com/uploads/post/img/509/feature_thumb_heroku-logo.jpg"></img>
            <img alt="MongoDB" className="skills-image"  src="https://img.icons8.com/color/452/mongodb.png"></img>

          </div>
        </div>

        <div className="projectCards">
        <div className="projectImage">
            <a target="_blank" rel="noopener noreferrer" href="https://dallas-cowboy-calculator.netlify.app/"><img className="projectImage" alt="Dallas-Calculator" src="https://res.cloudinary.com/darnycya/image/upload/v1616125740/Dallas_Calculator_orhtqo.png" /></a></div>
            <div className="projectDescription">
              <h5>Dallas Cowboy Calculator</h5> 
             
            <p className="projectP">A Dallas Cowboy themed caluclator. When the equation equals a number of a Dallas Cowboy player's jersey their stats pop up in a Modal. </p>
             
            <p className="projectP"><b>Tools used:</b>  </p>
            <br />
            <img alt="ReactJS" className="skills-image" src="https://www.pngitem.com/pimgs/m/664-6644509_icon-react -js-logo-hd-png-download.png"></img>
            <img alt="Javascript" className="skills-image" src="https://res.cloudinary.com/highereducation/image/upload/f_auto,fl_lossy,q_auto:eco/v1/ComputerScience.org/2015/10/js_logo-150x150.jpg"></img>
            <img alt="HTML" className="skills-image" src="https://res.cloudinary.com/highereducation/image/upload/f_auto,fl_lossy,q_auto:eco/v1/ComputerScience.org/2015/10/HTML5_logo-e1458852233788.png"></img>
            <img alt="CSS" className="skills-image" src="https://res.cloudinary.com/darnycya/image/upload/v1616420439/CSS3_logo_and_wordmark.svg_tmnjsl.png"></img>
          </div>
          </div>
         
          


        <div className="projectCards">
        <div className="projectImage">
            <a target="_blank" rel="noopener noreferrer" href="https://dallas-cowboys-roster.netlify.app/"><img className="projectImage" alt="Dallas-Roster" src="https://res.cloudinary.com/darnycya/image/upload/v1690090470/Screenshot_2023-07-23_at_1.33.56_AM_mzfjry.png" /></a></div>
            <div className="projectDescription">
            <h5>Dallas Cowboy Calculator Roster</h5> 
             
            <p className="projectP">A site where I can edit the Dallas Cowboy API that I created so that the Dallas Calculator can always be up-to-date. </p>
             
            <p className="projectP"><b>Tools used:</b>  </p>
            <br />
            <img alt="ReactJS" className="skills-image" src="https://www.pngitem.com/pimgs/m/664-6644509_icon-react -js-logo-hd-png-download.png"></img>
            <img alt="Javascript" className="skills-image" src="https://res.cloudinary.com/highereducation/image/upload/f_auto,fl_lossy,q_auto:eco/v1/ComputerScience.org/2015/10/js_logo-150x150.jpg"></img>
            <img alt="CSS" className="skills-image" src="https://res.cloudinary.com/darnycya/image/upload/v1616420439/CSS3_logo_and_wordmark.svg_tmnjsl.png"></img>
            <img alt="Heroku" className="skills-image" src="https://dailysmarty-production.s3.amazonaws.com/uploads/post/img/509/feature_thumb_heroku-logo.jpg"></img>
            <img alt="MongoDB" className="skills-image"  src="https://img.icons8.com/color/452/mongodb.png"></img>
          </div>
         </div>


      </div>
      </div>
  
  )
}
