import React from 'react'

export default function WorkExperience() {
  return (
    <div className="workExperienceContainer">
      <h3 className="workExperienceH3">Work Experience</h3>
      <div class="container">
<div class="main-timeline">

                        {/* <!-- start experience section--> */}
                        <div class="timeline">
                            <div class="icon"></div>
                            <div class="date-content">
                                <div class="date-outer">
                                    <span class="date">
                                            <span class="month">Present</span>
                                    <span class="year">2021</span>
                                    </span>
                                </div>
                            </div>
                            <div class="timeline-content">
                                <h5 class="title">Software Engineer</h5>
                                <p class="description">
                               I enhanced application functionality by fixing bugs, optimizing performance, and improving reliability. Working closely with the Design Team, I contributed to implementing new features, resulting in a substantial 30% increase in traffic. With proactive troubleshooting, I ensured a seamless user experience. Concurrently, I designed and developed custom software systems for large-scale telecommunications and data applications, serving 120+ million users. My re-architecting efforts led to a $1.61 billion annual revenue increase. Additionally, I supported QA with test plans, boosting efficiency by 15%.
                                </p>
                            </div>
                        </div>
                        {/* <!-- end experience section-->

                        <!-- start experience section--> */}
                        <div class="timeline">
                            <div class="icon"></div>
                            <div class="date-content">
                                <div class="date-outer">
                                    <span class="date">
                                            <span class="month">4 months</span>
                                    <span class="year">2021</span>
                                    </span>
                                </div>
                            </div>
                            <div class="timeline-content">
                                <h5 class="title">Instructional Associate</h5>
                                <p class="description">              
I taught 25 students a coding curriculum covering HTML, CSS, JavaScript, and Ruby. Evaluated 1,000+ assignments and assessments, mentoring students to create four standout projects for potential employers.
                                </p>
                            </div>
                        </div>
                        {/* <!-- end experience section-->

                        <!-- start experience section--> */}
                        <div class="timeline">
                            <div class="icon"></div>
                            <div class="date-content">
                                <div class="date-outer">
                                    <span class="date">
                                            <span class="month">3 months</span>
                                    <span class="year">2020</span>
                                    </span>
                                </div>
                            </div>
                            <div class="timeline-content">
                                <h5 class="title">Software Engineering Fellow</h5>
                                <p class="description">
                               
Designed and launched an interactive memory game and "EscapeNYC" app using HTML, CSS, JavaScript, React.js, and Ruby on Rails. Also developed a photo sharing platform with React.js, Express.js, PostgreSQL, and AWS for hosting.
                                </p>
                            </div>
                        </div>
                        {/* <!-- end experience section-->

                        <!-- start experience section--> */}
                        <div class="timeline">
                            <div class="icon"></div>
                            <div class="date-content">
                                <div class="date-outer">
                                    <span class="date">
                                            <span class="month">2 Years</span>
                                    <span class="year">2018</span>
                                    </span>
                                </div>
                            </div>
                            <div class="timeline-content">
                                <h5 class="title">Regional General Manager</h5>
                                <p class="description">
                                Managed five Westchester County retail locations, achieving top state sales. Implemented coaching programs for direct reports, fostering a capable team and driving sales. Demonstrated product expertise through coaching on brand information, styling tips, and key features.
                                </p>
                            </div>
                        </div>
                        {/* <!-- end experience section--> */}

                    </div>
</div>
{/* </div> */}
      {/* <div>
        <p className="workExperienceP">
          <span>Newsela - Software Engineer</span> | <i>January 2021 - Present</i><br />
        I enhanced application functionality by fixing bugs, optimizing performance, and improving reliability and efficiency. Working closely with the Design Team, I contributed to the architecture and implementation of new features, resulting in a substantial 30% increase in traffic. With my proactive troubleshooting skills, I efficiently resolved complex issues, ensuring a seamless user experience.
        </p>
    </div>
    <div>
         <p className="workExperienceP"><span>Verizon - Software Engineer</span> | <i>May 2021 - July 2022</i><br />
          I designed, developed, and tested custom software systems for large-scale telecommunications and data communications applications, serving 120+ million users. I re-architected the product details page using cutting-edge technologies like React, Redux, and Next.js, resulting in a $1.61 billion annual revenue increase. I supported the QA team by developing comprehensive test plans and addressing bugs promptly, leading to a 15% efficiency boost.
        </p>
      </div>
    <div>
         <p className="workExperienceP"><span>General Assembly - Instructional Associate</span> | <i>Jan 2021 - May 2021</i><br />
          Instructed a cohort of 25 students in a diverse coding curriculum including HTML, CSS, JavaScript, and Ruby. Evaluated and debugged over 1,000 student assignments and assessments. Mentored students to develop four impressive projects for prospective hiring managers.
        </p>
      </div>
    <div>
         <p className="workExperienceP">
         <span>General Assembly - Software Engineering Fellow</span> | <i>May 2020 - August 2020</i><br />
          Designed and launched an interactive memory game using HTML, CSS, JavaScript, and React.js. Created "EscapeNYC," a robust Ruby on Rails application enabling users to explore and share hiking trails around New York City. Developed a feature-rich photo sharing platform using a full-stack approach, integrating Amazon Web Services for photo hosting and employing React.js, Express.js, PostgreSQL, HTML, and CSS.
        </p>
      </div>
    <div>
         <p className="workExperienceP">
         <span>Motherhood Maternity - Regional General Manager</span> | <i>August 2018 - April 2020</i><br />
          Managed five retail locations in Westchester County, achieving top sales in the state. Implemented coaching and development programs for direct reports, fostering a capable team and driving sales through informed decision-making. Demonstrated expertise in product knowledge, providing comprehensive coaching on brand information, styling tips, fabrications, and key features and benefits.
        </p>
        </div> */}
      </div>
  )
}
