import React from 'react';
import './App.css';
import About from './Components/About/About'
import Projects from './Components/Projects/Projects'
import Contact from './Components/Contact/Contact'
import NavBar from './Components/NavBar/NavBar';
import HomePage from './Components/HomePage/HomePage';
import WorkExperience from './Components/WorkExperience/WorkExperience';



function App() {
  return (
    <>
      <NavBar />
      <section id="home"><HomePage /></section>

      <section id="about">
      <div className="aboutMeContainer">
          <About />
          <WorkExperience />
          </div>
      </section>
      <section id="projects"><Projects /></section>
      <section id="contact"><Contact /></section>
        
      </>
  );
}

export default App;
