import React from 'react'

export default function HomePage() {
  return (
    <div className="homePageContainer">
      <div className="homePageContent">
        <h1 className="homePageName">Darnycya Smith</h1>
        <h2 className="slideUp">SOFTWARE ENGINEER, FRONT END DEVELOPER</h2>
      </div>
      <div className="scrollContainer">
      <img className="scrollGif" alt="scroll-gif"src="https://res.cloudinary.com/darnycya/image/upload/v1686689120/output-onlinegiftools_3_el0k5s.gif"></img>
      </div>
  </div>
  )
}
