import React from 'react'

export default function Contact() {
  return (
    <div className="contactContainer">

      <div>
      <h3 className="contactMeH3">Contact Me</h3>
      <p className="contactP">I am actively seeking job opportunities and freelance work at the moment.<br />
        I am excited to make new connections and explore potential collaborations.<br />
          Looking forward to connecting with you!<br /> </p>
      </div>
      <div className="logoContainer">
      <a target="_blank" rel="noopener noreferrer" href="https://github.com/darnycya"><img className="logo-contacts" alt="github" src="https://img.icons8.com/?size=512&id=63777&format=png"/></a>
      <a target="_blank" rel="noopener noreferrer"  href="mailto:darnycya@gmail.com"><img className="logo-contacts" alt="email" src="https://img.icons8.com/?size=512&id=qyRpAggnV0zH&format=png"/></a>
      <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@darnycya"><img className="logo-contacts" alt="medium" src="https://img.icons8.com/?size=512&id=m8ClhVaNuUH7&format=png"/></a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/darnycya/"><img className="logo-contacts" alt="linkedin" src="https://img.icons8.com/?size=512&id=13930&format=png" /></a>
        </div>
      
        </div>
    
  )
}
